.retractable{
	display:none;
}
.retracted{
	display:block;
}
.inActive:hover{
	background:linear-gradient(90deg, rgb(3, 115, 251) 0%, rgb(3, 184, 229) 100%);
	border:1px solid #026eea;
	color: white;
	height:30px;
	width:100%;
	border-radius:5px;
	text-align:center;
} 
.currentActive{
	background:linear-gradient(90deg, rgb(3, 115, 251) 0%, rgb(3, 184, 229) 100%);
	border:1px solid #026eea;
	color: white;
	height:30px;
	width:100%;
	border-radius:5px;
	text-align:center;
}  

.acc{ 
  background:white;
	border:1px solid #1398eb;
	color: #0f94f3; 
	width:100%;
	border-radius:5px;
}
.inActive{
	background:white;
	border:1px solid #1398eb; 
	height:30px;
	width:100%;
	border-radius:5px;
	text-align:center;
}
a:active{
	text-decoration:none;
	color:inherit;
}
a:link{
	text-decoration:none;
	color:inherit;
}
a:visited{
	text-decoration:none;
	color:inherit;
}
.notifi .dropdown-menu {
  width: 500px;
}
.navbar .dropdown-menu a {
  color: #0ca6f3;
}
.navbar .dropdown-item:focus{
	background:linear-gradient(90deg, rgb(7, 165, 217) 0%, rgb(0, 160, 252) 100%);
	color: white;
}
*{
    font-weight: 300;
}
.cardbtn{
	background:linear-gradient(90deg, rgb(25, 0, 252) 0%, rgb(0, 189, 252) 100%);
	color: white;
}
.cnt{
background-color: #ffffff;
opacity: 1;
background: repeating-linear-gradient( -45deg, #07cae8, #0885d3 20px, #ffffff 20px, #ffffff 100px );
}
.shakeable{
	animation: shake 0.5s infinite;
}
@keyframes shake {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-0.25deg); }
  20% { transform: rotate(0.25deg); }
  30% { transform: rotate(0deg); }
  40% { transform: rotate(0.25deg); }
  50% { transform: rotate(-0.25deg); }
  60% { transform: rotate(0deg); }
  70% { transform: rotate(-0.25deg); }
  80% { transform: rotate(0.25deg); }
  90% { transform: rotate(0deg); }
  100% { transform: rotate(-0.25deg); }
}
.typed {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing;
  animation-duration: 1.5s;
  animation-timing-function: steps(30, end);
  animation-fill-mode: forwards;
}
  
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}